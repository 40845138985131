<template>
  <transition name="menu">
    <form-editor />
  </transition>
</template>

<script>
import formEditor from '../components/formEditor5'

export default {
  name: 'Editor5',
  components: { formEditor }
}
</script>

