<template>
  <v-container>
      <v-card
        class="pa-md-2 mx-lg-auto"
        outlined
      >

        <v-row class="fill-height">
    <v-col>
      <v-sheet height="64">
        <v-toolbar flat color="white">
          <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
            Today
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu bottom right>
            <template v-slot:activator="{ on }">
              <v-btn
                outlined
                color="grey darken-2"
                v-on="on"
              >
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Day</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Week</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Month</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = '4day'">
                <v-list-item-title>4 days</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="500">
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          :events="events"
          :event-color="getEventColor"
          :now="today"
          :type="type"
          :dark="false"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="updateRange"
        ></v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card
            color="grey lighten-4"
            min-width="350px"
            flat
          >
            <v-toolbar
              :color="selectedEvent.color"
              dark
            >
              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <span v-html="selectedEvent.details"></span>
            </v-card-text>
            <v-card-actions>
              <v-btn
                text
                color="secondary"
                @click="selectedOpen = false"
              >
                Tutup
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-col>
  </v-row>
        <v-row>
          <v-col class="text-right">
           
            <v-btn
              color="grey"
              class="mr-4"
              @click="doCancel()"
            >
              Tutup
            </v-btn>

          
          
          </v-col>
        </v-row>
      </v-card>

    <span style="font-size:8px;">{{idDaftarNama}}</span>

    <v-snackbar
        v-model="toast.show"
        :timeout="toast.timeout"
      >
        {{ toast.text }}
        <v-btn
          :color="toast.color"
          text
          @click="toast.show = false"
        >
          Close
        </v-btn>
      </v-snackbar>

      <v-dialog v-model="isLoading" fullscreen>
          <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.8);">
              <v-layout justify-center align-center>
                <v-card
                  width="70vW"
                  class="pa-md-2 mx-lg-auto"
                  outlined
                >
                  <v-list-item three-line centered>
                    <v-list-item-content>
                      <div class="overline mb-4">Processing</div>
                      <v-list-item-title class="headline mb-1">Please Wait....</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-progress-linear
                          indeterminate
                          color="yellow darken-2"
                        ></v-progress-linear>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

              </v-layout>
          </v-container>
      </v-dialog>

</v-container>
</template>

<script>
import secureStorage from '@/utils/secureStorage'
import { getDaftarPegawaiSTByUserPosAllStatus } from '@/api/daftarpegawaiST'
import { _GLOBAL } from '@/api/server'
import moment from 'moment'
import axios from 'axios'

export default {
  name: 'FormViewPegawai',
  components: {
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    seldaftarnama: {
      type: Object,
      default: () => {}
    },
  },
  data () {
    return {
      toast: { show: false, color:'green', text:'', timeout: 2000 },
      isLoading: false,
      idDaftarNama: 0,
      idhruserpositions_pegawai: 0,
      arrdStart: {},
      arrdEnd: {},
      today: moment().format('YYYY-MM-DD hh:mm'),
      focus: '',
      type: 'month',
      typeToLabel: {
        month: 'Month',
        week: 'Week',
        day: 'Day',
        '4day': '4 Days',
      },
      start: null,
      end: null,
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
      names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
    }
  },
  computed: {
    title () {
      const { start, end } = this
      if (!start || !end) {
        return ''
      }

      const startMonth = this.monthFormatter(start)
      const endMonth = this.monthFormatter(end)
      const suffixMonth = startMonth === endMonth ? '' : endMonth

      const startYear = start.year
      const endYear = end.year
      const suffixYear = startYear === endYear ? '' : endYear

      const startDay = start.day + this.nth(start.day)
      const endDay = end.day + this.nth(end.day)

      switch (this.type) {
        case 'month':
          return `${startMonth} ${startYear}`
        case 'week':
        case '4day':
          return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`
        case 'day':
          return `${startMonth} ${startDay} ${startYear}`
      }
      return ''
    },
    monthFormatter () {
      return this.$refs.calendar.getFormatter({
        timeZone: 'UTC', month: 'long',
      })
    },
  },
  mounted () {
    this.$refs.calendar.checkChange()

    if(this.seldaftarnama){
      this.idhruserpositions_pegawai = this.seldaftarnama.hruserpositions_pegawai
      this.idDaftarNama = this.seldaftarnama.id
      var curday = moment(this.seldaftarnama.dateStart).format('YYYY-MM-DD')
      this.today = moment(curday).format('YYYY-MM-DD hh:mm')
      this.setToday()

      var start = {
      date: moment(curday).startOf('month').format('YYYY-MM-DD'),
      day: parseInt(moment().startOf('month').format('D')),
      future: false,
      hasDay: true,
      hasTime: false,
      hour: 0,
      minute: 0,
      month: moment().format('M'),
      past: false,
      present: false,
      time: "",
      weekday: moment().startOf('month').weekday(),
      year: moment().format('YYYY')
    }
    var end = {
      date: moment(curday).endOf('month').format('YYYY-MM-DD'),
      day: parseInt(moment().endOf('month').format('D')),
      future: false,
      hasDay: true,
      hasTime: false,
      hour: 0,
      minute: 0,
      month: moment().format('M'),
      past: false,
      present: false,
      time: "",
      weekday: moment().endOf('month').weekday(),
      year: moment().format('YYYY')
    }
    this.arrdStart = start
    this.arrdEnd = end

      // console.log('first', start,end)
      this.updateRange({start, end})
    }
  },
  destroyed () {
  },
  methods: {
    viewDay ({ date }) {
      this.focus = date
      this.type = 'day'
    },
    getEventColor (event) {
      return event.color
    },
    setToday () {
      this.focus = this.today
    },
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
    showEvent ({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        setTimeout(() => this.selectedOpen = true, 10)
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },

    updateRange ({ start, end }) {
      const vm = this
      const events = []
      const min = new Date(`${start.date}T00:00:00`)
      const max = new Date(`${end.date}T23:59:59`)
      
      // console.log('updateRange', start, end)
      if (vm.idhruserpositions_pegawai > 0) {
        vm.isLoading = true
        var curday = moment(start).subtract(1,'month').format('YYYY-MM-DD')
        var curmonth = moment(curday).format('YYYY-MM')
        getDaftarPegawaiSTByUserPosAllStatus(vm.idhruserpositions_pegawai, curmonth, curmonth).then(response => {
          var rData = response.data
          vm.isLoading = false
          // console.log(rData)
          if (rData !== null) {
            rData.forEach(element => {
              if (moment(element.dateStart).isValid()) {
                element.dateStart = new Date(element.dateStart)
              }
              if (moment(element.dateEnd).isValid()) {
                element.dateEnd = new Date(element.dateEnd)
              }

              if(element.datasurat){
                var nosurat = '-'
                if(element.datasurat.noSurat) { 
                  nosurat = element.datasurat.noSurat
                }
                events.push({
                  name: '#'+ element.datasurat.id + " | " + nosurat,
                  start: this.formatDate(element.dateStart, false),
                  end: this.formatDate(element.dateEnd , false),
                  color: this.colors[this.rnd(0, this.colors.length - 1)],
                  details: element.datasurat.perihalSurat
                })
              }
              
            })
          }
        }).catch(err => {
          // console.log(err)
          vm.isLoading = false
          vm.toast = {
            show: true, color:'red', text: 'Fetch Data GAGAL, silakan refresh browser!', timeout: 2000
          }
        })
      }

      this.start = start
      this.end = end
      this.events = events

    },

    nth (d) {
      return d > 3 && d < 21
        ? 'th'
        : ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][d % 10]
    },
    rnd (a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
    formatDate (a, withTime) {
      return withTime
        ? `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()} ${a.getHours()}:${a.getMinutes()}`
        : `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`
    },
    doCancel(){
      //  // console.log('back')
      this.$emit('dialogviewPegawaiClose', true)
    },
    
  }
}

</script>


